import React, { ReactNode, useEffect, useState, Fragment, memo } from 'react';
import Image from 'next/legacy/image';
import useViewModels from '../../contexts/useViewModels';
import {
  ASSET_SERVER_ALTERNATIVE_BASE_URL,
  ASSET_SERVER_BASE_URL,
} from '../../../resources/js/constants/assetServer';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore tsc seems to bug out and misreport this line
import styles from './OptimizedImage.module.scss';
import EnhancedMarkdownImage from '../../../resources/js/components/EnhancedMarkdown/components/EnhancedMarkdownImage';
import { captureException } from '@sentry/react';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';
import isStorybook from '../../../resources/js/helpers/isStorybook';
import {
  HasViewModelBuilder,
  ViewModelBuilder,
} from '../../typings/viewModelBuilder';

type OptimizedImageProps = {
  src: string;
  alt: string;
  width?: number;
  height?: number;
  notFound?: boolean;
  isZoomable?: boolean;
  includeMargin?: boolean;
  priority?: boolean;
  quality?: number;
};

export function NonMemoizedOptimizedImage(
  props: OptimizedImageProps
): JSX.Element | null {
  const includeMargin = props.includeMargin ?? true;

  const { viewModels } = useViewModels();
  const [viewModel, setViewModel] = useState(props);

  useEffect(() => {
    const newViewModel = {
      ...props,
      ...(viewModels[viewModelBuilder.buildKey(props)] ?? {}),
    };

    setViewModel(newViewModel);
  }, [viewModels, props]);

  if (viewModel?.notFound || isStorybook())
    return (
      <EnhancedMarkdownImage
        src={viewModel?.src || props.src}
        alt={viewModel?.alt || props.alt}
        width={viewModel?.width || props.width || '100%'}
        height={viewModel?.height || props.height || 'auto'}
        includeMargin={viewModel?.includeMargin ?? props.includeMargin ?? true}
      />
    );

  if (
    (!viewModel?.src?.startsWith?.(ASSET_SERVER_BASE_URL) &&
      !viewModel?.src?.startsWith?.(ASSET_SERVER_ALTERNATIVE_BASE_URL)) ||
    !((viewModel?.alt?.length ?? 0) > 0 || viewModel?.alt === '') ||
    !viewModel?.width ||
    !viewModel?.height
  )
    return null;

  const SizeWrapper =
    props.width && props.height
      ? ({ children }: { children: ReactNode }) => (
          <div style={{ width: props.width, height: props.height }}>
            {children}
          </div>
        )
      : Fragment;

  const ZoomWrapper =
    props.isZoomable === false
      ? Fragment
      : ({ children }: { children: ReactNode }) => (
          <Zoom
            overlayBgColorEnd='rgba(0, 0, 0, 0.95)'
            overlayBgColorStart='rgba(0, 0, 0, 0)'
          >
            {children}
          </Zoom>
        );

  const imageProps = {
    ...viewModel,
  };
  delete imageProps.isZoomable;
  delete imageProps.includeMargin;

  return (
    <div
      className={
        styles.container +
        (includeMargin ? ` ${styles.includeMargin}` : '') +
        ' optimized-image'
      }
    >
      <SizeWrapper>
        <ZoomWrapper>
          {/* eslint-disable-next-line jsx-a11y/alt-text */}
          <Image {...imageProps} />
        </ZoomWrapper>
      </SizeWrapper>
    </div>
  );
}

const OptimizedImage = memo(
  NonMemoizedOptimizedImage,
  (prevProps, nextProps) =>
    prevProps.src === nextProps.src &&
    prevProps.width == nextProps.width &&
    prevProps.height === nextProps.height
);

export type OptimizedImageViewModelProps = {
  src: string;
  width?: number | string;
  height?: number | string;
};

const viewModelBuilder: ViewModelBuilder<OptimizedImageViewModelProps> = {
  buildKey: (props) =>
    `optimizedImage_${props.src}_${props.width}_${props.height}`,
  buildModel: async (props, { getPlaiceholder }) => {
    try {
      const { base64, img } = await getPlaiceholder(props.src);

      if (props.width && typeof props.width === 'string') {
        props.width = parseInt(props.width);
      }
      if (props.height && typeof props.height === 'string') {
        props.height = parseInt(props.height);
      }

      if (props.width && !props.height) {
        props.height = Math.round(
          img.height * ((props.width as number) / img.width)
        );
      }

      if (props.height && !props.width) {
        props.width = Math.round(
          img.width * ((props.height as number) / img.height)
        );
      }

      return {
        ...img,
        ...props,
        placeholder: 'blur',
        blurDataURL: base64,
      };
    } catch (error) {
      captureException(error);

      return {
        ...props,
        notFound: true,
      };
    }
  },
};

(
  NonMemoizedOptimizedImage as unknown as HasViewModelBuilder<OptimizedImageViewModelProps>
).viewModelBuilder = viewModelBuilder;
(
  OptimizedImage as unknown as HasViewModelBuilder<OptimizedImageViewModelProps>
).viewModelBuilder = viewModelBuilder;

export default OptimizedImage;
